import moment from 'moment';
import {round} from "lodash";

const exportToCSV = (headers, rows, name) => {
  //---create CSV Content

  let CSVContent = `${headers}\n`;
  rows.forEach(row => {
    CSVContent += `${row}\n`;
  });

  //---create CSV Link
  let a = document.createElement('a'),
    mimeType = 'text/csv;encoding:utf-8',
    fileName = `${name}-${moment().format('YYYY-MM-DD--HH-mm')}.csv`;

  if (navigator.msSaveBlob) { //---IE10
    navigator.msSaveBlob(new Blob([CSVContent], {
      type: mimeType
    }), fileName);
  } else if (URL && 'download' in a) { //---html5 A[download]
    a.href = URL.createObjectURL(new Blob([CSVContent], {
      type: mimeType
    }));
    a.setAttribute('download', fileName);
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  } else {
    location.href = 'data:application/octet-stream,' + encodeURIComponent(CSVContent); //---only this mime type is supported
  }
}


const createCSVAddresses = (addresses) => {
  let headers = '"User ID","Name","CompanyName","Department","Postal code","Address 1","Address 2","Address 3","STATES, etc.","COUNTRY","Tel","Fax","E-mail address"',
      rows = [];

  addresses.forEach(address => {
    let country = address.country ? `${address.country}` : ``,
        cityAndDistrict = address.district && address.district.length ?  `${address.address_3}, ${address.district}` : `${address.address_3}`;

    rows.push(`"${address.user_id}","${address.name}","","","${address.postal_code}","","${address.address_2}","${cityAndDistrict}","","${country}","${address.tel}","","${address.user_id}"`)
  });

  exportToCSV(headers, rows, 'addresses');
}

const createCSVProducts = (products) => {
  let headers = '"The content product name","HS-code","The origin Code","Weight per 1","A unit price","A unit price(a unit)"';

  let rows = [];
  products.forEach(product => {
    let weight = product.weight ? `"${round(product.weight)}"` : "";
    rows.push(`"${product.title}","","JP",${weight},"${product.price}","JPY"`)
  });

  exportToCSV(headers, rows, 'products');
}

export {
  createCSVAddresses,
  createCSVProducts
}