const barcodeKeyboardMap = [
  {
    keyCode: 48,
    value: '0'
  },
  {
    keyCode: 49,
    value: '1'
  },
  {
    keyCode: 50,
    value: '2'
  },
  {
    keyCode: 51,
    value: '3'
  },
  {
    keyCode: 52,
    value: '4'
  },
  {
    keyCode: 53,
    value: '5'
  },
  {
    keyCode: 54,
    value: '6'
  },
  {
    keyCode: 55,
    value: '7'
  },
  {
    keyCode: 56,
    value: '8'
  },
  {
    keyCode: 57,
    value: '9'
  },
  {
    keyCode: 65,
    value: 'A'
  },
  {
    keyCode: 66,
    value: 'B'
  },
  {
    keyCode: 67,
    value: 'C'
  },
  {
    keyCode: 68,
    value: 'D'
  },
  {
    keyCode: 69,
    value: 'E'
  },
  {
    keyCode: 70,
    value: 'F'
  },
  {
    keyCode: 71,
    value: 'G'
  },
  {
    keyCode: 72,
    value: 'H'
  },
  {
    keyCode: 73,
    value: 'I'
  },
  {
    keyCode: 74,
    value: 'J'
  },
  {
    keyCode: 75,
    value: 'K'
  },
  {
    keyCode: 76,
    value: 'L'
  },
  {
    keyCode: 77,
    value: 'M'
  },
  {
    keyCode: 78,
    value: 'N'
  },
  {
    keyCode: 79,
    value: 'O'
  },
  {
    keyCode: 80,
    value: 'P'
  },
  {
    keyCode: 81,
    value: 'Q'
  },
  {
    keyCode: 82,
    value: 'R'
  },
  {
    keyCode: 83,
    value: 'S'
  },
  {
    keyCode: 84,
    value: 'T'
  },
  {
    keyCode: 85,
    value: 'U'
  },
  {
    keyCode: 86,
    value: 'V'
  },
  {
    keyCode: 87,
    value: 'W'
  },
  {
    keyCode: 88,
    value: 'X'
  },
  {
    keyCode: 89,
    value: 'Y'
  },
  {
    keyCode: 90,
    value: 'Z'
  },
  {
    keyCode: 96,
    value: '0'
  },
  {
    keyCode: 97,
    value: '1'
  },
  {
    keyCode: 98,
    value: '2'
  },
  {
    keyCode: 99,
    value: '3'
  },
  {
    keyCode: 100,
    value: '4'
  },
  {
    keyCode: 101,
    value: '5'
  },
  {
    keyCode: 102,
    value: '6'
  },
  {
    keyCode: 103,
    value: '7'
  },
  {
    keyCode: 104,
    value: '8'
  },
  {
    keyCode: 105,
    value: '9'
  },  {
    keyCode: 189,
    value: '-'
  },
];

const formatBarcode = (keyCodes) => {
  let code = '';
  keyCodes.forEach(item => {
    if (
        item.keyCode >= 65 && item.keyCode <= 90 ||
        item.keyCode >= 48 && item.keyCode <= 57 ||
        item.keyCode >= 96 && item.keyCode <= 105 ||
        item.keyCode === 189
    ) {
      for (let i = 0; i < barcodeKeyboardMap.length; i++ ){
        if (barcodeKeyboardMap[i].keyCode === item.keyCode) {
          code += barcodeKeyboardMap[i].value;
          break
        }
      }

    }
  });
  return code;
}

const printBarcodes = (barcodes) => {
  localStorage.setItem('barcodes', JSON.stringify(barcodes))
  window.open(`${window.origin}/barcodes`, '_blank');
}

export {
  printBarcodes,
  formatBarcode,
}